
import { defineComponent } from "vue";
import UsersCatalogue from "@/components/catalogues/UserCatalogue/UserCatalogue.vue";

export default defineComponent({
  components: { UsersCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
